import { faPlay }                             from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon }                    from "@fortawesome/react-fontawesome";
import { getPodcast }                         from "application/requests/podcast";
import React, { useEffect, useRef, useState } from "react";
import { useVisualizer }                      from "react-audio-viz";
import Countdown                              from "react-countdown";
import { Container }                          from "reactstrap";
import Snowfall                               from "react-snowfall";

import Image from "./background.png";

import "./index.css";

const NewYearEveScene = () => (
  <>
    <Container className="mt-5">
      <Other />
    </Container>
  </>
);

export default NewYearEveScene;

const mp3Duration = (4 * 3600) + 6; // Convert hours to seconds
const startTime = new Date("2024-12-31T20:00:02");

const Other = () => {
  const audioRef = useRef(new Audio());
  const [status, setStatus] = useState("");
  const [ReactAudioViz, initializeVisualizer] = useVisualizer(audioRef);

  // const snowflakes = new Snowflakes({
  //   wind:     false,
  //   rotation: false
  // });

  function setPlaybackPosition() {
    const now = new Date();

    if (now >= startTime) {
      const elapsedTime = (now - startTime) / 1000; // Elapsed time in seconds

      if (elapsedTime < mp3Duration) {
        // Within playable window
        setStatus("playing");

        // Set the playback position when metadata is loaded
        console.log(elapsedTime);
        if (audioRef.current) {
          audioRef.current.currentTime = elapsedTime;
        }
      } else {
        // Stream ended
        setStatus("too_late");
      }
    } else {
      // Stream hasn't started yet
      setStatus("too_soon");
    }
  }

  function handlePlay() {
    audioRef.current.onplay = initializeVisualizer;
    setPlaybackPosition();
    if (status === "playing") {
      audioRef.current.play();
    }
  }

  function handleClick() {
    if (audioRef.current.paused) {
     handlePlay();
    }
  }

  useEffect(() => {
    getPodcast(1443).then((response) => {
      audioRef.current.src = response.podcast.audio_file_url;
    });
    return () => {
      audioRef.current.pause();
    };
  }, []);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      handlePlay();
      if (status === "too_late") {
        return (
          <div style={ { fontSize: "2rem", textAlign: "center", marginTop: "50px" } }>
            Le live est maintenant terminé <br />
            <br />
            <img src={ Image } alt="background" width="80%" />
          </div>
      );
      }
      return (
        <>
          <div
            onClick={ () => handleClick() }
            onKeyDown={ () => { } }
            role="button"
            tabIndex="0"
            className="embed-responsive new-eve-embed"
            style={ {
            backgroundImage:    `url(${Image})`,
            backgroundSize:     "cover",
            backgroundPosition: "center",
            display:            "flex",
            alignItems:         "center",
            justifyContent:     "center",
            cursor:             audioRef.current.paused ? "pointer" : "default",
            position:           "relative"
          } }
          >
            { audioRef.current.paused && (
            <a href="#" className="player-button big" onClick={ () => { handleClick(); } } style={ { position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "100px", height: "100px", display: "flex", alignItems: "center", justifyContent: "center" } }>
              <FontAwesomeIcon icon={ faPlay } size="2x" />
            </a>
          ) }
            <ReactAudioViz
              model={ customModel({
                darkMode:       false,
                reversed:       false,
                fadeBars:       true,
                scale:          1,
                color:          "#F5C924",
                binSize:        15,
                frequencyRange: [0, 14000]
              })}
            />
          </div>
        </>
      );
    }
      // Render a countdown
      return (
        <div style={ { fontSize: "2rem", textAlign: "center", marginTop: "50px" } }>
          Le live commencera dans <br />
          { days > 0 && (<>{ days } jours</>) } { " " }
          { hours > 0 && (<>{ hours } heures</>) } { " " }
          { minutes > 0 && (<>{ minutes } minutes et</>) } { " " }
          { seconds } secondes
          <br /><br />
          <img src={ Image } alt="background" width="80%" />
        </div>
      );
  };

  return (
    <>
      <Countdown
        daysInHours={ false }
        date={ startTime }
        renderer={ renderer }
      />
      <Snowfall />
    </>
  );
};

export const parseCSSColor = (input) => {
  if (input.substr(0, 1) == "#") {
    const collen = (input.length - 1) / 3;
    const fact = [17, 1, 0.062272][collen - 1];
    return {
      r: Math.round(parseInt(input.substr(1, collen), 16) * fact),
      g: Math.round(parseInt(input.substr(1 + collen, collen), 16) * fact),
      b: Math.round(parseInt(input.substr(1 + 2 * collen, collen), 16) * fact),
    };
  }
  const components = input
    .split("(")[1]
    .split(")")[0]
    .split(",");
  return {
    r: Number(components[0]),
    g: Number(components[1]),
    b: Number(components[2]),
    a: components.length > 3 ? Number(components[3]) : 1,
  };
};

export const DEFAULT_OPTIONS = {
    darkMode:       true,
    reversed:       false,
    fadeBars:       true,
    scale:          1.0,
    color:          "#F44E3B",
    binSize:        25,
    frequencyRange: [0, 16000],
};

const customModel = (options = {}) => {
    const { reversed, darkMode, scale, color, frequencyRange, binSize, fadeBars, } = { ...DEFAULT_OPTIONS, ...options };
    const parsedColor = parseCSSColor(color);
    const colorMakerOptions = {
        dark:  (c, intensity) => c * intensity,
        light: (c, intensity) => c + (255 - c) * (1 - intensity),
    };
    const colorMaker = colorMakerOptions[darkMode ? "dark" : "light"];
    const frequencyIndexSelectorOptions = {
        normal:  (x, width, L) => Math.floor((x / width) * L),
        reverse: (x, width, L) => L - Math.floor((x / width) * L),
    };
    const frequencyIndexSelector = frequencyIndexSelectorOptions[reversed ? "reverse" : "normal"];
    const MIN_INTENSITY = 0.5;
    const offPixel = darkMode
        ? parseCSSColor("#000000")
        : parseCSSColor("#ffffff");
    return (x, y, width, height, frequencyData) => {
        const binnedX = Math.floor(x / binSize) * binSize;
        const frequencyIndex = frequencyIndexSelector(binnedX, width,
        // Tell the index selector to limit its options to the provided frequency range
        Math.floor(((frequencyRange[1] - frequencyRange[0]) / 24000) * frequencyData.length));
        // The frequency magnitude in [0, 1]
        const frequencyMagnitudeForThisPixel = frequencyData[frequencyIndex] / 255;
        const range = height / 2;
        const scaledRange = range * scale;
        // yPosition in [0, 1]
        const yPosition = Math.abs(range - y) / scaledRange;
        const scaledIntensity = (1 - yPosition / frequencyMagnitudeForThisPixel) * (1 - MIN_INTENSITY)
            + MIN_INTENSITY;
        const turnOn = yPosition <= frequencyMagnitudeForThisPixel;
        return turnOn
            ? {
                r: fadeBars
                    ? colorMaker(parsedColor.r, scaledIntensity)
                    : parsedColor.r,
                g: fadeBars
                    ? colorMaker(parsedColor.g, scaledIntensity)
                    : parsedColor.g,
                b: fadeBars
                    ? colorMaker(parsedColor.b, scaledIntensity)
                    : parsedColor.b,
            }
            : {
              r: 0,
              g: 0,
              b: 0,
              a: 1,
            };
    };
};
